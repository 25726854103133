import React, { Component } from 'react';
import Footer from './footer';

import ExclusiveImage from '../assets/images/aboutus/medal.png';
import HarmonyImage from '../assets/images/aboutus/lotus.png';
import TimeFrameImage from '../assets/images/aboutus/alarm-clock.png';
import StyleImage from '../assets/images/aboutus/woman-clothes.png';
import FitImage from '../assets/images/aboutus/diet.png';
import BudgetImage from '../assets/images/aboutus/money.png';
import ChoiceImage from '../assets/images/aboutus/two-hearts.png';

class AboutUs extends Component {
  render() {
    return (
      <>
        <section id="about-us">
          <div class="about-us-title">About Us</div>
          <div className="slogan-description">
            {/* <img src="https://img-static.popxo.com/app_photos/images/2240/original/indian-wedding-dress-guide-red-traditional-lehenga-tarun-tahiliani.jpg" alt="kaavya soul of fashion"/> */}
            <div className="video-responsive">
              <iframe title="kaavya-youtube-video" src="https://www.youtube.com/embed/QiLb5az4rg8?autoplay=1&mute=1"></iframe>
            </div>
            <div className="about-kaavya">
              <p>You deserve the comfort of knowing that your clothing is made thoughtfully in every way. <b>This is section for what Kaavya is all about.</b></p>
              <p>Mindfulness is at the core of everything we do, from choosing organic, sustainable, and fair trade materials, to working with ethical partners, to making self-care part of our employee benefits, and so much more.</p>
            </div>
          </div>
          {/* <div className="about-kaavya-content">
            <h2>At Kaavya, we believe you deserve clothing that fits your life -</h2>
            <h2>not the other way around.</h2>
            <p>We weave conscious comfort into every stitch of Kaavya boutique to empower you to go further. Coupling sustainable and ethical craftsmanship with our unbelievably soft, stylish, everyday staples, we’ve created a wardrobe made to support and grow with you through each life moment.</p>
            <h2>Easy to Wear. Easy on the Earth. Unbelievably Soft.</h2>
          </div> */}
          <div className="about-owner">
            <div className="about-designer-info">
              <h4>Our Designer</h4>
              <p>Our Lead Designer, Uma Nemkul Amatya, founded Kaavya in 2005 with Ramila Nemkul. Back then, we were among the few who opened boutiques in Nepal. We value our customer and we do our best to satisfy our customer with the perfect dress with comfort at an affordable price. We believe in teamwork, a happy team can make happy customers.</p>
            </div>
            <img src="https://files.kaavyaboutique.com/KaavyaSOF(20201129031525).jpg" alt="kaavya"></img>
          </div>
          {/* <div className="slogan">
            <h1>Soul of Fashion</h1>
          </div> */}
          <div className="about-how-we-work">
            <div className="about-how-we-work-info">
              <h4>How We Design</h4>
              <p>We design the dress that feets you perfect. Fitting dress can make a person look more beautiful and can have confortable wearing too. Which leads to the self-confidence. At Kaavya, we design you dress according to your preference and in your budget. Also we add our skill to design the most beautiful dress.</p>
            </div>
            {/* <img src="https://files.kaavyaboutique.com/KaavyaSOF(20201006121858).jpg" alt="kaavya"></img> */}
          </div>
          <div className="how-we-design-grid-container">
            <div class="how-we-design-grid-item">
              {/* <img style={{ width: "33%" }} src="https://neetalulla.com/wp-content/uploads/2019/08/Group-49.png" alt="Choice" /> */}
              <img style={{ width: "40%" }} src={ChoiceImage} alt="Choice" />
              <div className="item-description">
                <h6>Choice</h6>
                <p>Every detail in your dress will be subject to your preference and liking.</p>
              </div>
            </div>
            <div class="how-we-design-grid-item">
              {/* <img style={{ width: "21%" }} src="https://neetalulla.com/wp-content/uploads/2019/08/Group-50.png" alt="Fit" /> */}
              <img style={{ width: "34%" }} src={FitImage} alt="Fit" />
              <div className="item-description">
                <h6>FIT</h6>
                <p>The garment will be made as per your measurements and so is guaranteed to fit you perfectively.</p>
              </div>
            </div>
            <div class="how-we-design-grid-item">
              <img style={{ width: "34%" }} src={ExclusiveImage} alt="EXCLUSIVITY" />
              <div className="item-description">
                <h6>EXCLUSIVITY</h6>
                <p>Because the dress is made as per your specificity, it will be a distinctive and unique piece.</p>
              </div>
            </div>
            <div class="how-we-design-grid-item">
              <img style={{ width: "34%" }} src={HarmonyImage} alt="HARMONY" />
              <div className="item-description">
                <h6>HARMONY</h6>
                <p>The garment can be designed around your accessories so that you have a complete ensemble in place.</p>
              </div>
            </div>
            <div class="how-we-design-grid-item">
              <img style={{ width: "40%" }} src={BudgetImage} alt="BUDGET" />
              <div className="item-description">
                <h6>BUDGET</h6>
                <p>Not necessarily more expensive as you can also select something from the store and tone down the embellishments as per your budget and liking.</p>
              </div>
            </div>
            <div class="how-we-design-grid-item">
              <img style={{ width: "38%" }} src={StyleImage} alt="STYLE" />
              <div className="item-description">
                <h6>STYLE</h6>
                <p>Know what your stylistic priorities are, what type of silhouettes suit you according to your body type. Keep an open mind and consider all the options.</p>
              </div>
            </div>
            <div class="how-we-design-grid-item">
              <img style={{ width: "30%" }} src={TimeFrameImage} alt="TIME FRAME" />
              <div className="item-description">
                <h6>TIME FRAME</h6>
                <p>This would be based on the materials, embroidery and other detailing. Also, we would consider the courier delivery time in this case.</p>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </>
    )
  }
}

export default AboutUs;