import React, {Component} from 'react';

import { MDBNavItem, MDBNavLink, MDBContainer, MDBRow, MDBCol } from "mdbreact";

class NavImgSlider extends React.Component {

    
    state = {
        isOpen: false
    };

    render() {
        return (
            <div class="k-img-slider" >
                <MDBNavItem className="nav-slider">
                    <a  href="http://measure.kaavyaboutique.com/">
                        <figure>
                            <img src="https://files.kaavyaboutique.com/KaavyaSOF(20201111074954).jpg" alt="" srcset="" />
                            <figcaption>Women</figcaption>
                        </figure>
                    </a>
                </MDBNavItem>

                <MDBNavItem className="nav-slider">
                    <a  href="http://measure.kaavyaboutique.com/">
                        <figure>
                            <img src="https://files.kaavyaboutique.com/KaavyaSOF(20201111074954).jpg" alt="" srcset="" />
                            <figcaption>Men</figcaption>
                        </figure>
                    </a>
                </MDBNavItem>
                <MDBNavItem className="nav-slider">
                    <a  href="http://measure.kaavyaboutique.com/">
                        <figure>
                            <img src="https://files.kaavyaboutique.com/KaavyaSOF(20201111074954).jpg" alt="" srcset="" />
                            <figcaption>Collection</figcaption>
                        </figure>
                    </a>
                </MDBNavItem>
                <MDBNavItem className="nav-slider">
                    <a  href="http://measure.kaavyaboutique.com/">
                        <figure>
                            <img src="https://files.kaavyaboutique.com/KaavyaSOF(20201111074954).jpg" alt="" srcset="" />
                            <figcaption>Women</figcaption>
                        </figure>
                    </a>
                </MDBNavItem>
                <MDBNavItem className="nav-slider">
                    <a  href="http://measure.kaavyaboutique.com/">
                        <figure>
                            <img src="https://files.kaavyaboutique.com/KaavyaSOF(20201111074954).jpg" alt="" srcset="" />
                            <figcaption>Women</figcaption>
                        </figure>
                    </a>
                </MDBNavItem>
                <MDBNavItem className="nav-slider">
                    <a  href="http://measure.kaavyaboutique.com/">
                        <figure>
                            <img src="https://files.kaavyaboutique.com/KaavyaSOF(20201111074954).jpg" alt="" srcset="" />
                            <figcaption>Women</figcaption>
                        </figure>
                    </a>
                </MDBNavItem>
            </div>
        );
    }
}

export default NavImgSlider;
