import React, { Component } from 'react';

class PageNotFound extends Component {
	state = {
		count: 0,
	};

	handleCount = () => {
		const { count } = this.state;
		console.log(count);
		let newCount = count + 1;
		console.log(count, newCount);
		this.setState({ count: newCount });
	};

	render() {
		return (
			<div className="pageNotFound">
				<div className="pagewrap">
					<h1>404</h1>
					<h3>{this.state.count}</h3>
					<button onClick={this.handleCount}>+</button>
				</div>
			</div>
		);
	}
}

export default PageNotFound;
