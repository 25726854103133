import React, { Component } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

class Loader extends Component {
	render() {
		return (
			<div className="sweet-loading">
				<ClipLoader size={50} color={'red'} border="3px solid" loading={true} />
			</div>
		);
	}
}

export default Loader;
