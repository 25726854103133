import React, { Component } from 'react';
import { AddressMap } from './maps';
import Footer from './footer';

class ContactUs extends Component {
      render() {
            return (
                <div>
                    <section id="contact">
                        <div className="container">
                            <h3 className="contact-us-title">Contact us</h3>
                            <p className="contact-us-description">Thank you for visiting our website. If you have any queies or suggestion feel free to contact us.</p>
                            <div className="contact-us-contact-detail">
                                <h4>Call us</h4>
                                <a href="tel:+977-01-5540088">+977-01-5440088</a>
                            </div>
                            <div className="contact-us-contact-detail">
                                <h4>Email</h4>
                                <a href = "mailto:contact@kaavyaboutique.com">contact@kaavyaboutique.com</a>
                            </div>
                            <div className="contact-us-contact-detail">
                                <h4>Location</h4>
                                <p>Patan Dhoka-Madan Smarak Road, Lalitpur 44700</p>
                            </div>
                            <AddressMap />
                        </div>
                    </section>
                <Footer />
                </div>
                )
      }
}

export default ContactUs;