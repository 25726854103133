import React, { Component } from 'react'

class InstagramLink extends Component {
      render() {
            return (
                  <div className="insta-body">
                        <h4 >Instagram</h4>
                        <p>Explore the latest from the house of Kaavya SOF</p>
                        <a href="https://www.instagram.com/kaavyaboutique/" target="_blank" rel="noopener noreferrer" title="Follow Us on Instagram">Follow Us on Instagram</a>
                  </div>
            )
      }
}


export default InstagramLink;