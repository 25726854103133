import React from 'react';

const NetworkErrorAlert = () => {
	return (
		<div className="container-fluid">
			<div className="alert alert-solid-danger alert-bold" role="alert">
				<div className="alert-icon">
					<i className="la la-warning"></i>
				</div>
				<div className="alert-text">
					<strong>Error!</strong> Content rendered failed due to Newtork error.
				</div>
			</div>
		</div>
	);
};

export default NetworkErrorAlert;
