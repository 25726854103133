import React, { Component } from 'react';
import Loader from '../components/utils/loader';
import { getWhatsnewImage } from '../components/services/whatsnewService';
import NetworkErrorAlert from '../components/utils/networkAlert';
import Lightbox from 'react-image-lightbox';
import Footer from './footer';

class WhatsNew extends Component {
	state = {
		whatsnewData: [],
		loading: true,
		errors: false,
		photoIndex: 0,
		isOpen: false,
	};

	async componentDidMount() {
		try {
			const { data: whatsnewData } = await getWhatsnewImage(this.props.match.params.id);
			this.setState({ whatsnewData, loading: false });
		} catch (err) {
			this.setState({ errors: err });
		}
	}

	renderImages = () => {
		let photoIndex = -1;
		const { whatsnewData } = this.state;

		return (
			whatsnewData &&
			whatsnewData.map(m => {
				photoIndex++;
				const privateKey = photoIndex;
				return (
					<figure className="col-md-4 col-6" key={m.id} onClick={() => this.setState({ photoIndex: privateKey, isOpen: true })}>
						<img src={m.imageUrl} className="img-fluid z-depth-1" alt={m.name} />
						{m.sale && (
							<div className="ribbon">
								<div className="ribbon5">{`${m.salePercentage}% OFF`}</div>
							</div>
						)}
					</figure>
				);
			})
		);
	};

	render() {
		const { loading, errors, isOpen, whatsnewData, photoIndex } = this.state;
		return (
			<>
				{errors && errors.message ? (
					<NetworkErrorAlert />
				) : loading ? (
					<Loader />
				) : (
					<>
						<div className="gallery-wrap">
							<div className="container">
								<div className="row">{this.renderImages()}</div>
								{isOpen && (
									<Lightbox
										mainSrc={whatsnewData[photoIndex].imageUrl}
										nextSrc={whatsnewData[(photoIndex + 1) % whatsnewData.length].imageUrl}
										prevSrc={whatsnewData[(photoIndex + whatsnewData.length - 1) % whatsnewData.length].imageUrl}
										imageTitle={whatsnewData[photoIndex].name}
										onCloseRequest={() => this.setState({ isOpen: false })}
										onMovePrevRequest={() =>
											this.setState({
												photoIndex: (photoIndex + whatsnewData.length - 1) % whatsnewData.length,
											})
										}
										onMoveNextRequest={() =>
											this.setState({
												photoIndex: (photoIndex + 1) % whatsnewData.length,
											})
										}
									/>
								)}
							</div>
						</div>
						<Footer />
					</>
				)}
			</>
		);
	}
}

export default WhatsNew;
