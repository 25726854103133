import axios from 'axios';
import { apiKaavya } from '../app-config';

const apiEndpoint = `${apiKaavya}/api/trending`;

export function getTrending() {
	return axios.get(`${apiEndpoint}`);
}

export function getTrendingImage(id) {
	return axios.get(`${apiEndpoint}image`);
}

