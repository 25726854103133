import axios from 'axios';
import { apiKaavya } from '../app-config';

const apiEndpoint = `${apiKaavya}/api/whatsnew`;

export function getWhatsnew() {
	return axios.get(`${apiEndpoint}`);
}

export function getWhatsnewImage(id) {
	return axios.get(`${apiEndpoint}image`);
}