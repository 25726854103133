import React, { useEffect } from 'react';
import './assets/scss/App.scss';
import Collection from './views/collection';
import Gallery from './views/gallery';
import Trending from './views/trending';
import WhatsNew from './views/whatsnew';
import PageNotFound from './views/pagenotFound';
import AboutUs from './views/aboutUs';
import ContactUs from './views/contactUs';
import ReactGa from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
// import Navbar from './views/navbar';
import NavbarTitle from './views/navbarReact';
import NavImgSlider from './views/navImgSlider';

function App() {

	useEffect(() => {
		ReactGa.initialize('UA-175462970-1');
		ReactGa.pageview(window.location.pathname + window.location.search);
	}, [])

	ReactPixel.init('500267004452210');
	ReactPixel.pageView();
	ReactPixel.track("SiteOpen", "Kaavya website is opened.");

	return (
		<BrowserRouter>
			{/* <Navbar /> */}
			{/* ToDo compare checking branch and add code (meta tags, urls of collection) */}
			<NavbarTitle />
			<NavImgSlider />
			<Switch>
				<Route path="/" exact component={Collection} />
				<Route path="/collection/:id/:name" exact component={Gallery} />
				<Route path="/trending/:id" exact component={Trending} />
				<Route path="/whatsnew/:id" exact component={WhatsNew} />
				<Route path="/pagenotfound" component={PageNotFound} />
				<Route path="/about-us" exact component={AboutUs} />
				<Route path="/contact-us" exact component={ContactUs} />
				<Redirect to="/pagenotfound" />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
